/*
 * File: general.utilities.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

const randomizeEntryPosition = () => {

	// Create angle offset
	let angle = (Math.random() * (5 - 12) + 12).toFixed(1); // Between 5 and 12 degrees
	const sign = (Math.random() >= 0.5) ? '-' : '';
	angle = sign + angle;

	// Create padding
	const padding = (Math.random() * (20 - 28) + 28).toFixed(0); // Between 20px and 28px padding

	// Return values
	return { angle, padding };
};


/**
 * Exports
 */

module.exports = {
	randomizeEntryPosition
};
