module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"screen-overall-padding general-content-section-padding exhibit-module medium\"><div class=\"general-content-section white center-align\"><div class=\"grid-x align-center\" data-equalizer><div class=\"cell small-12 medium-9 large-5\" data-equalizer-watch><div class=\"text-view-content\"><p>")
    ; __append( content )
    ; __append("</p></div><div class=\"action-container\"><a name=\"exhibit-backward\" class=\"animate spacer\"><div class=\"left-arrow\"></div>Back to Gallery</a> <a name=\"exhibit-asset-forward\" class=\"animate\">Continue<div class=\"right-arrow\"></div></a></div></div></div></div></div>")
  }
  return __output;

}