/*
 * File: general.constants.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Available Locales
 */

exports.AVAILABLE_LOCALES = ['en'];


/**
 * Sources
 */

exports.SOURCES = {
	ARTIST: 'artist',
	EXHIBIT: 'exhibit',
	EXHIBITS: 'exhibits',
	GUESTBOOK: 'guestbook'
};


/**
 * General
 */

exports.SCROLL_LOAD_TOP = -560;
exports.SCROLL_LOAD_BOTTOM = 80;
exports.DEFAULT_TIMEZONE = 'America/New_York';
exports.DEFAULT_LOCALE = 'en-US';


/**
 * Cookies
 */

exports.REACTION_SCHEMA_COOKIE = 'reaction-schema';
exports.SESSION_TOKEN_COOKIE = 'e_s_token';
exports.LOCALE_COOKIE = 'pl_locale';
exports.USER_IMPERSONATION_COOKIE = 'user_imp_token';
exports.CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
exports.CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
exports.CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
exports.CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';
exports.CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
exports.LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Headers
 */

exports.USER_IMPERSONATION_HEADER = 'x-user-imp-token';
