/*
 * File: utilities.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const { v4: uuidv4 } = require('uuid');

// Constants
const { AVAILABLE_LOCALES } = require('../../../constants/general.constants');


/**
 * Helpers
 */

// Helper to create locale-free base url
exports.reduceToBaseURL = (p) => {
	let path = p;
	AVAILABLE_LOCALES.forEach((locale) => {
		const testArray = path.split(locale);
		if (testArray.length > 1 && testArray[0] === '/') {
			const testPath = testArray[1];
			if ((testPath.length === 0 || testPath.charAt(0) === '/' || testPath.charAt(0) === '?')) {
				path = path.replace(locale, '').replace('//', '/');
			}
		}
	});
	return path;
};


/**
 * General Handlers
 */

exports.getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return undefined;
};

exports.isConsentLevelEnabled = (level) => {
	const cookie = exports.getCookie(level);
	if (cookie != null) return cookie === 'true';
	return false;
};

exports.generateRandomIdentifier = (length) => {
	if (length != null) {
		const possible = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let id = '';
		for (let i = 0; i < length; i += 1) id += possible.charAt(Math.floor(Math.random() * possible.length));
		return id;
	}
	return uuidv4();
};


/**
 * Modal Handlers
 */

exports.showModalWithId = (id) => {
	if (!$(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).addClass('show-modal');
	}
};

exports.hideModalWithId = (id) => {
	if ($(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).removeClass('show-modal');
	}
};


/**
 * Alert Handlers
 */

exports.showAlert = (message, id = 'input-alert') => {

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (!isAlertVisible) {

		// Set alert content
		alertText.text(message);
		alertText.addClass('shadow');

		// Hide element
		setTimeout(() => { alert.addClass('solid-element'); }, 100);

		// Show alert
		alert.animate({ top: `-${alert.outerHeight() + 15}px` }, 300, null);

		// Scroll to top to see error
		if (id === 'input-alert') {
			if ($('html, body').scrollTop() > 130) {
				$('html, body').animate({ scrollTop: '130px' }, 300);
			}
		}
	}
};

exports.hideAlert = (id = 'input-alert') => {

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (isAlertVisible) {

		// Set alert content
		alertText.removeClass('shadow');

		// Hide element
		setTimeout(() => {
			alert.removeClass('solid-element');
		}, 200);

		// Hide alert
		alert.animate({ top: '0px' }, 300, () => {

			// Remove content
			alertText.text('');
		});
	}
};
