module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"screen-overall-padding general-content-section-padding exhibit-module\"><div class=\"general-content-section white center-align\"><div class=\"grid-x align-center\"><div class=\"cell small-")
    ; __append(escapeFn( columns.small ))
    ; __append(" medium-")
    ; __append(escapeFn( columns.medium ))
    ; __append(" large-")
    ; __append(escapeFn( columns.large ))
    ; __append("\"> ")
    ;  if (title !== '') { 
    ; __append("<h2>")
    ; __append(escapeFn( title ))
    ; __append("</h2>")
    ;  } 
    ; __append(" <div>")
    ; __append( content )
    ; __append("</div></div></div></div></div>")
  }
  return __output;

}