/*
 * File: metrics.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Exports
 */

exports.handlePageView = (source, entityId) => {

	// Record view
	const params = { source, entity_id: entityId };
	Parse.Cloud.run('recordViewWithParameters', params);
};

exports.recordAction = (id, data) => {

	// Record view
	const params = { id, data };
	Parse.Cloud.run('recordActionWithParameters', params);
};
