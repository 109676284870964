/*
 * File: cookies.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Components
const recaptchaScript = require('../../../views/structure/recaptcha.ejs')();
const hotjarScript = require('../../../views/structure/hotjar_head.ejs');


/**
 * Handlers
 */

exports.handlePerformanceConsentUpdate = (granted) => {
	if (granted === true) {

		// Trigger GTM Performance
		try {
			window.dataLayer.push({ event: 'consent_given_performance' });
		} catch (err) {}

		// Set up reCAPTCHA if needed
		if ($('#recaptcha-page-script').length === 0) {
			$(recaptchaScript).appendTo(document.head);
		}
	}
};

exports.handleAnalyticsConsentUpdate = (granted, globalState) => {
	if (granted === true) {

		// Trigger GTM Analytics
		try {
			window.dataLayer.push({ event: 'consent_given_analytics' });
		} catch (err) {}

		// Set up Hotjar if needed
		if ($('#hotjar-page-script').length === 0 && process.env.ENV === 'production') {
			const script = hotjarScript(globalState);
			$(script).appendTo(document.head);
		}
	}
};

exports.handleTargetingConsentUpdate = (granted) => {
	if (granted === true) {

		// Trigger GTM Targeting
		try {
			window.dataLayer.push({ event: 'consent_given_targeting' });
		} catch (err) {}
	}
};
