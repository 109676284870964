module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"screen-overall-padding general-content-section-padding exhibit-module large\"><div class=\"general-content-section white center-align\"><div class=\"grid-x align-center content-container\"><div class=\"cell small-11 medium-7 large-5\"><h2>")
    ; __append(escapeFn( title ))
    ; __append("</h2><p>")
    ; __append( description )
    ; __append("</p><h3 class=\"instruction-text\">Click an Expression to Explore More</h3><div class=\"quotation spacer\"><p>\"Healing is first and foremost not feeling alone. It is vulnerability and transparency and understanding that not everyone can or will accept the truth. Your truth. The truth that shapes us.\"</p></div><div class=\"action-container\"><a name=\"exhibit-backward\" class=\"animate spacer\"><div class=\"left-arrow\"></div>Step Back</a> <a name=\"exhibit-forward\" class=\"animate\">Continue<div class=\"right-arrow\"></div></a></div></div></div><div class=\"grid-x align-center relative z-index-2 feature-gallery-dynamic-item-section\"><div class=\"cell small-12 medium-12 large-10\"><div class=\"gallery-dynamic-item-container\"> ")
    ;  assets.forEach((asset, index) => { 
    ; __append(" ")
    ; 
							let positionClass = '';
							switch (index) {
								case 0:
									positionClass = 'radial-1';
									break;
								case 1:
									positionClass = 'radial-2';
									break;
								case 2:
									positionClass = 'radial-3';
									break;
								case 3:
									positionClass = 'radial-4';
									break;
								case 4:
									positionClass = 'radial-5';
									break;
								case 5:
									positionClass = 'radial-6';
									break;
								case 6:
									positionClass = 'radial-7';
									break;
								case 7:
									positionClass = 'radial-8';
									break;
								case 8:
									positionClass = 'radial-9';
									break;
								case 9:
									positionClass = 'radial-10';
									break;
								case 10:
									positionClass = 'radial-11';
									break;
								case 11:
									positionClass = 'radial-12';
									break;
								case 12:
									positionClass = 'radial-13';
									break;
								case 13:
									positionClass = 'radial-14';
									break;
								default:
									break;
							}
						
    ; __append(" <div class=\"gallery-dynamic-item ")
    ; __append(escapeFn( positionClass ))
    ; __append(" animate\" name=\"exhibit-gallery-asset\" data-asset-id=\"")
    ; __append(escapeFn( asset.id ))
    ; __append("\"> ")
    ;  if (asset.type === 'image') { 
    ; __append(" <img class=\"animate\" src=\"")
    ; __append(escapeFn( asset.url ))
    ; __append("\"> ")
    ;  } else { 
    ; __append(" <p class=\"animate preview-block\">")
    ; __append( asset.excerpt )
    ; __append("</p> ")
    ;  } 
    ; __append(" </div> ")
    ;  }); 
    ; __append(" </div></div></div></div></div>")
  }
  return __output;

}