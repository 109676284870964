module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grid-item guestbook-entry guestbook-entry-item animate\"><div style=\"transform:rotate(")
    ; __append(escapeFn( angle ))
    ; __append("deg);-webkit-transform:rotate(")
    ; __append(escapeFn( angle ))
    ; __append("deg);-moz-transform:rotate(")
    ; __append(escapeFn( angle ))
    ; __append("deg)\"><div style=\"padding: ")
    ; __append(escapeFn( padding ))
    ; __append("px\"> ")
    ;  if (locals.signature != null) { 
    ; __append("<img src=\"")
    ; __append(escapeFn( signature ))
    ; __append("\">")
    ;  } 
    ; __append(" ")
    ;  if (locals.name != null) { 
    ; __append("<span>")
    ; __append(escapeFn( name ))
    ; __append("</span>")
    ;  } 
    ; __append(" </div></div></div>")
  }
  return __output;

}