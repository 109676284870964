/*
 * File: module.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const ejs = require('ejs/ejs.min');

// Entry
const { componentState } = require('../../../js/entry/exhibit');

// Theme
require('./theme.css');
require('../../../theme/full.css');

// Configuration
const configuration = require('./data/exhibit.configuration');

// Templates
const contentView = require('./components/content_view.ejs');
const galleryView = require('./components/gallery_view.ejs');
const individualImageView = require('./components/individual_image_view.ejs');
const individualTextView = require('./components/individual_text_view.ejs');


/**
 * Global Variables
 */

let currentStage = 0;
let currentAsset = {};


/**
 * Renderer Handlers
 */

const renderComponent = (data) => {

	// Initialize state
	let component = '';
	const actions = null;

	// Create component for type
	switch (data.type) {
		case 'content': {

			// Render content
			const content = ejs.render(data.content, { exhibit: componentState.exhibit, locale: componentState.locale }, {});

			// Render content
			component = contentView({ ...data, content });

			break;
		} case 'gallery': {

			// Render gallery
			component = galleryView(data);

			break;
		} case 'image': {

			// Render asset
			component = individualImageView(data);

			break;
		} case 'text': {

			// Render asset
			component = individualTextView(data);

			break;
		} default:
			break;
	}

	// Return component
	return { component, actions };
};


/**
 * Handlers
 */

const handleSwitchStage = async (component, actions, { showIntro, showInfo, showStage }) => {

	// Get containers
	const container = $('#exhibit-container');
	const introductionContainer = $('#exhibit-intro-container');
	const informationContainer = $('#exhibit-information-container');
	const stageContainer = $('#exhibit-stage-container');

	// Fade out container
	container.fadeOut('slow', async () => {

		// Update container content
		stageContainer.html(component);

		// If necessary, run actions
		if (actions != null) actions();

		// Update intro container visibility
		if (showIntro === true) introductionContainer.show();
		else introductionContainer.hide();

		// Update info container visibility
		if (showInfo === true) informationContainer.show();
		else informationContainer.hide();

		// Update stage container visibility
		if (showStage === true) stageContainer.show();
		else stageContainer.hide();

		// Scroll to top
		$('html, body').scrollTop('0px');

		// Show container
		container.fadeIn('slow');

		// Enable animation
		$('.feature-gallery-dynamic-item-section').toggleClass('dynamic');
	});
};

const handleMoveStep = async ({ forward, stage }) => {

	// Update state
	if (forward === true) {

		// Update current stage
		currentStage += 1;

		// Set new state
		const stateData = {
			title: document.title,
			route: window.location.pathname,
			currentStage,
			type: 'stage'
		};
		window.history.pushState(stateData, document.title, window.location.pathname);

	} else if (forward === false) {

		// Update current stage
		currentStage -= 1;

	} else if (stage != null) {

		// Set current stage
		currentStage = stage;
	}

	// Switch stage
	let component = '';
	let actions = null;
	let config = {};
	if (currentStage === 0) {

		// Create configuration
		config = { showIntro: true, showInfo: true, showStage: false };

	} else {

		// Get current stage
		const stageObj = configuration.stages[currentStage - 1];

		// Render component
		const obj = renderComponent(stageObj);
		component = obj.component;
		actions = obj.actions;

		// Create configuration
		config = { showIntro: false, showInfo: stageObj.visibility.info, showStage: true };
	}

	// Render component
	handleSwitchStage(component, actions, config);
};

const showAssetDetail = (id) => {

	// Get component for id
	const stage = configuration.stages[currentStage - 1];

	// Get asset
	currentAsset = stage.assets.find((item) => item.id === id);

	// Render component
	const { component, actions } = renderComponent(currentAsset);

	// Render component
	handleSwitchStage(component, actions, { showIntro: false, showInfo: false, showStage: true });
};


/**
 * Navigation Handlers
 */

const handleBackAction = () => {

	// Get current state
	const currentState = window.history.state;

	// Update display based on type
	if (currentState.type === 'stage') {

		// Move backward
		handleMoveStep({ stage: currentState.currentStage });

	} else if (currentState.type === 'asset') {

		// Set current stage
		currentStage = currentState.currentStage;

		// Show asset detail
		showAssetDetail(currentState.assetId);
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	/**
	 * Exhibit Navigation Actions
	 */

	// Handle click on exhibit forward action
	$(document).on('click', '[name="exhibit-forward"]', (e) => {
		e.stopPropagation();

		// Move forward
		handleMoveStep({ forward: true });
	});

	// Handle click on exhibit asset forward action
	$(document).on('click', '[name="exhibit-asset-forward"]', (e) => {
		e.stopPropagation();

		// Get component for id
		const stage = configuration.stages[currentStage - 1];

		// Get asset
		let index = stage.assets.findIndex((item) => item.id === currentAsset.id);
		index = (index + 1) < stage.assets.length ? index + 1 : 0;

		// Set new current asset
		currentAsset = stage.assets[index];

		// Render component
		const { component, actions } = renderComponent(currentAsset);

		// Render component
		handleSwitchStage(component, actions, { showIntro: false, showInfo: false, showStage: true });
	});

	// Handle click on exhibit backward action
	$(document).on('click', '[name="exhibit-backward"]', (e) => {
		e.stopPropagation();

		// Move backward
		window.history.back();
	});

	// Handle click on individual asset action
	$(document).on('click', '[name="exhibit-gallery-asset"]', function (e) {
		e.stopPropagation();

		// Get parameters
		const assetId = $(this).data('asset-id');

		// Set new state
		const stateData = {
			title: document.title,
			route: window.location.pathname,
			currentStage,
			assetId,
			type: 'asset'
		};
		window.history.pushState(stateData, document.title, window.location.pathname);

		// Show asset detail
		showAssetDetail(assetId);
	});


	/**
	 * Navigation Actions
	 */

	// Handle pop state
	$(window).bind('popstate', () => {

		// Handle back action
		handleBackAction();
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {};


/**
 * Init
 */

(async function initialize() {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Replace initial state
	const stateData = {
		title: document.title,
		route: window.location.pathname,
		currentStage,
		type: 'stage'
	};
	window.history.replaceState(stateData, document.title, window.location.pathname);
}());
