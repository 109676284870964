/*
 * File: guestbook.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const SignaturePad = require('signature_pad').default;
const Masonry = require('masonry-layout');
const imagesLoaded = require('imagesloaded');
const trimCanvas = require('trim-canvas');
const validator = require('validator');
const axios = require('axios');

// Utilities
const {
	showModalWithId, hideModalWithId, showAlert, hideAlert, generateRandomIdentifier
} = require('./utilities');
const { randomizeEntryPosition } = require('../../../utilities/general.utilities');

// Constants
const { SOURCES } = require('../../../constants/general.constants');

// Templates
const guestbookEntry = require('../../../views/partials/guestbook/guestbook_entry.ejs');

// jQuery Arguments
imagesLoaded.makeJQueryPlugin($);


/**
 * Global Variables
 */

let globalState = null;
let guestbookEntries = null;
let localeObj = null;


/**
 * Elements
 */

let masonry;
let signatureCanvas;
let signaturePad;


/**
 * Helpers
 */

function cloneCanvas(oldCanvas) {

	// create a new canvas
	const newCanvas = document.createElement('canvas');
	const context = newCanvas.getContext('2d');

	// set dimensions
	newCanvas.width = oldCanvas.width;
	newCanvas.height = oldCanvas.height;

	// apply the old canvas to the new one
	context.drawImage(oldCanvas, 0, 0);

	// return the new canvas
	return newCanvas;
}

const dataURItoBlob = (dataURI) => {

	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	// New Code
	return new Blob([ab], { type: mimeString });
};


/**
 * Handlers
 */

const handleGuestbookSubmission = async () => {

	// Get values
	const name = $('#guestbook-name').val();
	const feedback = $('#guestbook-feedback').val();

	// Validate inputs
	if (signaturePad.isEmpty() && validator.isEmpty(name, { ignore_whitespace: true })
		&& validator.isEmpty(feedback, { ignore_whitespace: true })) {
		showAlert('Please create a signature or add feedback to submit.', 'guestbook-input-alert');
		return;
	}

	// Get elements
	const button = $('#guestbook-submission-button');

	// Disable button
	button.attr('disabled', true);

	// Create base parameters
	const params = {};
	if (!validator.isEmpty(name, { ignore_whitespace: true })) params.name = name;
	if (!validator.isEmpty(feedback, { ignore_whitespace: true })) params.feedback = feedback;

	// If including signature, upload image
	if (!signaturePad.isEmpty()) {

		// Clone canvas
		const clone = cloneCanvas(signatureCanvas);

		// Trim canvas whitespace
		trimCanvas.default(clone);

		// Get data url
		const signature = clone.toDataURL('image/png');

		// Create file name
		const filename = `${generateRandomIdentifier()}-${generateRandomIdentifier()}.png`;

		// Create form data
		const blob = dataURItoBlob(signature);
		const formData = new FormData();
		formData.append('file', blob, filename);

		// Post signature
		let response = null;
		try {
			response = await axios.post(`${process.env.BASE_API_URL}/_api/media/guestbook/create`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (e) {}

		// Parse and add parameter
		if (response != null && response.data != null && response.data.image_url != null) {
			params.signature_url = response.data.image_url;
		} else {

			// Enable button
			button.attr('disabled', false);

			// Show error
			showAlert("Whoops! We weren't able to sign the guestbook. Please try again.", 'guestbook-input-alert');
			return;
		}
	}

	// Add parameters
	params.context = globalState.context;
	params.locale_id = localeObj.locale_id;
	params.geo = localeObj.geo;

	// Set exhibit if necessary
	if (globalState.exhibit != null) params.exhibit_id = globalState.exhibit.exhibit_id;

	// Add ReCAPTCHA token
	try {
		params.recaptcha_token = await grecaptcha.execute(process.env.RECAPTCHA_PUBLIC_KEY, { action: 'submit_exhibit_feedback' });
	} catch (e) { }

	// Submit message
	Parse.Cloud.run('submitGuestbookEntryWithParameters', params)
		.then(() => {

			// Enable button
			button.attr('disabled', false);

			// Show error
			showAlert('Thank you for signing our Guestbook! Your name will appear in a few minutes.', 'guestbook-input-alert');

			// Reset content
			signaturePad.clear();
			$('#guestbook-name').val('');
			$('#guestbook-feedback').val('');

			// Hide alert
			setTimeout(() => {
				hideAlert('guestbook-input-alert');
			}, 5000);
		})
		.catch(() => {

			// Enable button
			button.attr('disabled', false);

			// Show error
			showAlert("Whoops! We weren't able to sign the guestbook. Please try again.", 'guestbook-input-alert');

			// Hide alert
			setTimeout(() => {
				hideAlert('guestbook-input-alert');
			}, 5000);
		});
};


/**
 * Canvas Handlers
 */


const resizeCanvas = () => {
	const ratio = Math.max(window.devicePixelRatio || 1, 1);
	signatureCanvas.width = signatureCanvas.offsetWidth * ratio;
	signatureCanvas.height = signatureCanvas.offsetHeight * ratio;
	signatureCanvas.getContext('2d').scale(ratio, ratio);
	signaturePad.clear(); // otherwise isEmpty() might return incorrect value
};


/**
 * Visibility Handlers
 */

const showGuestbook = () => {
	showModalWithId('guestbook-modal');
	setTimeout(() => {
		masonry.layout();
	}, 300);
};

const hideGuestbook = () => {
	hideModalWithId('guestbook-modal');
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	/**
	 * Canvas Actions
	 */

	$(document).on('click', '[name="guestbook-canvas-clear"]', (e) => {
		e.stopPropagation();

		// Clear canvas
		signaturePad.clear();
	});


	/**
	 * Submission Actions
	 */

	$(document).on('click', '#guestbook-submission-button', (e) => {
		e.stopPropagation();

		// Handle submission
		handleGuestbookSubmission();
	});


	/**
	 * Navigation Actions
	 */

	// Handle click on guestbook close
	$(document).on('click', '[name="guestbook-close"]', (e) => {
		e.stopPropagation();

		// Dismiss modal
		hideGuestbook();
	});


	/**
	 * Window Actions
	 */

	$(window).on('resize', () => {
		resizeCanvas();
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	/**
	 * Guestbook Submission Inputs
	 */

	// Handle focus
	$('#guestbook-name, #guestbook-feedback').focus(() => {

		// Hide alert
		hideAlert('guestbook-input-alert');
	});

	// Handle blur
	$('#guestbook-name, #guestbook-feedback').blur(() => {

		// Hide alert
		hideAlert('guestbook-input-alert');
	});

	// Handle keyup on name
	$('#guestbook-name').keyup((e) => {
		if (e.keyCode !== 13) {

			// Hide alert
			hideAlert('guestbook-input-alert');
		}
	});

	// Handle keyup on feedback
	$('#guestbook-feedback').keyup((e) => {
		if (e.keyCode !== 13) {

			// Hide alert
			hideAlert('guestbook-input-alert');
		}
	});
};


/**
 * Init
 */

const initialize = async (data) => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Set data
	globalState = data;
	localeObj = data.locale;

	// Get guestbook data
	if (data != null && data.entries != null) {
		guestbookEntries = data.entries;
	}

	// Check for guestbook data
	if (guestbookEntries == null) {

		// Fetch guestbook entries
		try {
			const params = {
				contexts: [
					SOURCES.EXHIBIT,
					SOURCES.EXHIBITS,
					SOURCES.ARTIST,
					SOURCES.GUESTBOOK
				],
				options: {
					visual: true,
					feedback: false
				}
			};
			const entries = await Parse.Cloud.run('fetchGuestbookEntriesWithParameters', params);
			if (entries != null) {

				// Set entries
				guestbookEntries = entries;

				// Append entries
				const html = guestbookEntries.map((entry) => {
					const entryObj = { ...entry, ...randomizeEntryPosition() };
					return guestbookEntry(entryObj);
				}).join('');
				$('#guestbook-grid').append(html);
			}
		} catch (e) {}
	}

	// Get canvas element
	signatureCanvas = document.getElementById('guestbook-canvas');

	// Create signature pad
	signaturePad = new SignaturePad(signatureCanvas, {
		penColor: '#4d708c'
	});

	// Resize canvas
	resizeCanvas();

	// Set up Masonry
	masonry = new Masonry('.guestbook-grid', {
		itemSelector: '.grid-item',
		stamp: '.guestbook-stamp',
		isAnimated: true
	});

	// Re-layout after images load
	$('#guestbook-grid').imagesLoaded(() => {
		masonry.layout();
	});
};


/**
 * Exports
 */

module.exports = {
	initialize,
	showGuestbook
};
